import './signup.scss';
import 'components/view/Hubspot.scss';
import React from 'react';
import { Link } from 'gatsby';
import HubspotForm from 'react-hubspot-form';

import Container from 'components/layout/Container';
import Section from 'components/layout/Section';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import { trackEvent } from 'utils/googleAnalytics'

const Loader = (
    <div className="loader">
        <FontAwesomeIcon icon={['fad', 'spinner-third']} spin />
    </div>
);

const SignUp = () => {
    return (
        <Container theme="light-blue" className="container-signUp signUp paddingY5">
            <Section className="sectionLeft"></Section>

            <Section className="sectionRight">
                <div className="innerRight">
                    <Link className="signUpLogo" to="/">
                        <img src="/assets/logos/blueflag-logo.svg" alt="Blue Flag logo" width="156px" height="31px" />
                    </Link>

                    <h2 className="signUpTitle">Request Access</h2>
                    <p className="signUpSubTitle">Request access to our platform today—no credit card required</p>

                    <div className="formWrapper">
                        <HubspotForm portalId="19517217" formId="043d6a70-7500-42ba-a529-35744dd77db0" loading={Loader} />
                    </div>
                </div>
            </Section>
        </Container>
    );
};

export default SignUp;
